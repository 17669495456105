<template>
  <row>
    <i-col span="24">
      <div
        style="
          font-weight: bold;
          font-size: 35px;
          margin: 10px 0;
          text-align: center;
        "
      >
        About Us
      </div>
      <hr />
    </i-col>
    <i-col span="8">
      <img
        src="../../assets/logo.jpg"
        alt="shangxue logo"
        style="width: 100%; margin: 80px 0"
      />
    </i-col>
    <i-col span="16">
      <card :bordered="false">
        <div
          style="
            font-weight: bold;
            font-size: 15px;
            margin: 15px;
            text-align: justify;
          "
        >
          Shangxue Technology Pty Ltd （MelbStudy）originates from a free study
          forum in Monash, it attracts a huge number of students to participate
          in forum. Except for free charge of study resources, then forum
          founder starts to charge individual users for exquisitely prepared
          courses when more and more students need help from us.
          <br /><br />
          We focus on IT training for international students who are studying at
          Monash and Melbourne University. Our online and offline courses are
          related to IT, Banking and Finance and other majors in the University.
          We help students get used to campus life quickly and teach them how to
          learn difficult units. Our private tutors are all excellent students,
          they all have years of teaching experience. Our students consist of s
          degree students and master's degree students， students all get D or
          HD level results after studying in Shangxue.
          <br /><br />
          Our business is growing up dramatically since we started, since we try
          our best to provide the most excellent service to customers, that’s
          our secret and advantage in the competitive market.
        </div>
      </card>
    </i-col>
  </row>
</template>
<script>
export default {};
</script>
